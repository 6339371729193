import React from 'react'

import Categories from '../../components/views/settings/categories/categories'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function CategoriesPage() {
   return (
      <LayoutLoggedIn>
         <Categories />
      </LayoutLoggedIn>
   )
}
