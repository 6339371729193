import { PATH } from '../../../../constants/global'
import { TABLE, CRUD } from '../../../../constants/tables'

export const CRUD_TABLE = {
    extraClassName: 'table-actions',
    modalTitle: ' Category',
    placeholder: ' categories',
    defaultActiveValue: { id: '', name: ''},
    defaultActionState : 0,
    addButtonText: 'Add Category',
    key: 'Categories',
    modalAddTitle: 'Name',
    objectKey: 'name',
    objectKeyTitleCase: 'Name',
    primaryActionText: CRUD.primaryActionText,
    secondaryActionText: CRUD.secondaryActionText,
    primaryDeleteText: CRUD.primaryDeleteText,
    crudPaths: PATH.CATEGORIES,
    tableTitle: TABLE.TITLE.CATEGORIES,
    header: 'Name'
}
